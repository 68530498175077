<template>
  <div class="login">
    <top :title="$route.query.type == 'setPwd'?'设置密码':'完善个人信息'"/>
    <!-- logo部分 -->
    <header>
      <div class="title">
        <div class="logo" :clase="$route.query.type !== 'setPwd' ? 'logo_no_height' : ''">
          <img src="@/assets/img/logo@3x.png" alt="" />
        </div>
        <p>惠医保直付药房</p>
      </div>
    </header>
    <div class="box">
      <div class="register_text">
        {{ $route.query.type == "setPwd" ? "设置密码" : "完善个人信息" }}
      </div>
      <!-- 证件号码 -->
      <div v-if="$route.query.type != 'setPwd'">
        <div @click="show = true">
          <input
            type="text"
            placeholder="请选择证件类型"
            class="box_input input_type"
            :value="typeStr"
            disabled
          />
          <van-icon name="arrow-down" color="#888" />
        </div>
        <input
          type="text"
          placeholder="请输入证件号码"
          class="box_input"
          @input="handleInput($event.target.value)"
        />
<!--        <div class="btn" @click="typeClick">确定</div>-->
      </div>
      <!-- 修改密码 -->
      <div>
        <input type="text" placeholder="请设置6-12位密码" class="box_input input_type" v-model="textStr" v-show="showPwd"/>
        <input type="password" placeholder="请设置6-12位密码" class="box_input input_type" v-model="textStr" v-show="!showPwd"/>
          <van-icon :class="$route.query.type !== 'setPwd' ? 'eyes' : ''" name="eye-o" color="#888" v-show="showPwd"  @click="showPwd = !showPwd" />
          <van-icon :class="$route.query.type !== 'setPwd' ? 'eyes' : ''" name="closed-eye" color="#888" v-show="!showPwd"  @click="showPwd = !showPwd" />
        <input type="text" placeholder="请再次输入密码" class="box_input input_type" v-model="reuText" v-show="showPwd"/>
      <input type="password" placeholder="请再次输入密码" class="box_input input_type" v-model="reuText" v-show="!showPwd"/>
      <div class="btn" @click="submit">完成</div>
      </div>

    </div>
    <van-popup
      v-model="show"
      position="bottom"
      style="border-top-left-radius: 8px; border-top-right-radius: 8px"
    >
      <div class="type_title">证件类型</div>
      <div v-for="(item, index) in cardTypes" :key="index">
        <div
          :class="['popup_text', cardType == item.value ? 'atv' : '']"
          @click="handleClick(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="dialogShow" :show-confirm-button='false' :lock-scroll="false" style="overflow-y:auto">
        <div class="dialog_title">惠医保隐私政策</div>
        <div class="dialog_text">
           <Privacy/>
        </div>
        <div class="dialog_btn">
          <span @click="$router.go(-1)">不同意</span>
          <span class="success_btn" @click="dialogShow = false">同意</span>
        </div>
    </van-dialog>
  </div>
</template>

<script>
import Privacy from '../text/privacy'
import { setPassword,register,oauth} from '@/api/_api/register'
import { checkIdcard } from '../../utils/util'
export default {
  components:{Privacy},
data() {
    return {
      dialogShow:false,
      istrue: false,
      show: false,
      showPwd:false,
      inptype:'text',
      list: [
        {
          text: "身份证",
          id: "0",
        },
        {
          text: "港澳通行证",
          id: "1",
        },
        {
          text: "护照",
          id: "2",
        },
      ],
      cardType: "", //证件类型
      textStr: "",
      typeStr:'',
      idCard:'',//证件号码
      reuText:''
    };
  },
  computed:{
    dict(){
      return this.$store.state.dict || [];
    },
    cardTypes(){
      let arr = [];
      this.dict && this.dict.forEach(element=>{
        if(element.groupId == 'cardType'){
          arr.push({label:element.dictName,value:element.dictId})
        }
      })
      return arr;
    }
  },
  methods: {
    handleInput(val){
      this.idCard = val.trim();
    },
    //判断身份证类型跟身份证是否存在
    typeClick(){
      if(!this.idCard || !this.cardType){
        this.$toast('请完善个人信息');
        return
      }
      let obj = {
        cardType:this.cardType,
        idCard:this.idCard
      }
      this.$store.commit('SET_CARD',obj)
      let params = {
        cardType:this.cardType,
        idCard:this.idCard
      }

      return params
      // checkCard(params).then(res=>{
      //   if(res.code == 200){
      //     this.$router.push({path:'/register',query:{type:'setPwd',add:'true'}})
      //   }
      // })
    },
    //获取用户输入内容
    handleClick(val) {
      this.cardType = val.value;
      this.typeStr = val.label;
      this.show = false;
    },
    //注册成功请求接口跳转
    login(parmas){
      this.$toast.loading({
        message: "登陆中...",
        forbidClick: true,
      });
      let { name, phone, password } = parmas;
      let str = `${name}-${phone}`;
      let url = `/auth/oauth/token?username=${encodeURIComponent(
        str
      )}&password=${password}&client_id=mobile&client_secret=123456&grant_type=password&scope=server`;
      oauth(url).then((res) => {
        if (res.code == 200) {
          this.$toast.clear();
          this.$store.commit("USER_DATA", res.data);
          localStorage.setItem('user_data',JSON.stringify(res.data))
          this.$cookies.set("token", res.data.token);
          this.$router.push({ path: "/home" });
        }
      });
    },
    submit(){
      let cardParams = {}
      if (this.$route.query.type !== 'setPwd') {
        cardParams = this.typeClick()
        let type =  checkIdcard(this.idCard);
        if(!type) return this.$toast('身份证格式错误')
      }
      if(!this.textStr || !this.reuText){
        this.$toast('请输入密码')
        return
      }
      if(this.textStr !== this.reuText){
        this.$toast('密码不一致，请重新输入')
        return
      }
      if(/-/g.test(this.textStr) || (this.textStr.length < 6 || this.textStr.length > 12)){
        this.$toast('请输入长度6-12且不带有‘-’字符的密码')
        return
      }
      let {verobj} = this.$store.state;
      let params = {
        ...verobj,
        ...cardParams,
        password:this.textStr,
      }
      if(this.$route.query.add){
        let { idCard ,cardType} = this.$store.state.card;
        params.idCard = idCard;
        params.cardType =  cardType;
        //注册接口
        register(params).then(res=>{
          if(res.code == 200){
            this.$toast.success({
              message:'注册成功，正在为您登录',
              forbidClick:true,
              duration:1000
            })
            setTimeout(() => {
              this.login(params)
            }, 1000);
          }
        })
      }else{
        //设置密码回到登录页
        setPassword(params).then(res=>{
          if(res.code == 200){
            this.$toast.success({
              message:'修改成功,请重新登录',
              forbidClick:true,
              duration:1000
            })
            setTimeout(() => {
              this.$cookies.remove('token')
              this.$router.push('/')
            }, 1000);
          }
        })
      }

    }
  },
  mounted(){
    //设置密码页不弹隐私框
    if(this.$route.query.add) {
        this.dialogShow = true
    }
    this.cardType = this.cardTypes[0].value;
    this.typeStr = this.cardTypes[0].label;
  },
};
</script>

<style scoped lang="scss">
.dialog_btn{
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  span{
    width: 50%;
    text-align: center;
    padding: vw(24) 0;
  }
  .success_btn{
    font-weight: 600;
    border-left: 1px solid #eee;
    color: #019cf4;
  }
}
.dialog_title{
  padding: vw(30) 0 vw(20);
  text-align: center;
  font-weight: 600;
}
.dialog_text{
  height: vw(512);
  overflow-y: auto;
}
.btn{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.register_text {
  font-size: vw(48);
  margin-bottom: vw(48);
  color: #484848;
}
.login {
  width: 100%;
  position: relative;
  .reg {
    color: red;
    font-size: vw(24);
    width: 80%;
    margin: 0 auto;
  }
  header {
    width: 100%;
    height: vw(550 + 22);
    background-color: $indexcolor;
    position: relative;
    .title {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      .logo {
        width: vw(120);
        height: vw(120);
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      p {
        margin-top: vw(40);
        color: white;
        font-size: vw(40);
      }
    }
  }
  .atv {
    color: #019cf4;
    font-weight: 600;
  }
  .box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, 25%);
    width: vw(654);
    height: vw(690);
    box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.2);
    border-radius: vw(8);
    background: #fff;
    padding: vw(48);
    box-sizing: border-box;
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #a1a1a1;
      font-size: vw(28);
    }
    .box_input {
      border: none;
      width: 100%;
      padding: vw(20) vw(48);
      border-radius: vw(50);
      box-sizing: border-box;
      background: #f9f9f9;
      font-size: vw(28);
      color: #333;
      margin-bottom: vw(28);
    }
    .btn {
      margin-top: vw(32);
      background: #019cf4;
      border-radius: vw(50);
      color: #fff;
      text-align: center;
      padding: vw(18) 0;
    }
    .box_text {
      display: flex;
      align-items: center;
      font-size: vw(24);
      color: #999999;
      letter-spacing: -0.52px;
      margin-top: vw(20);
      div {
        margin-left: vw(4);
      }
    }
  }
}
.popup_text {
  padding: vw(24) 0;
  font-size: vw(32);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f4f4f4;
}
.van-icon {
  position: absolute;
  right: vw(100);
  top: vw(180);
  color: #888;
}
.eyes {
  position: absolute;
  right: vw(100);
  top: vw(382.5);
  color: #888;
}
.input_type {
  position: relative;
}
.type_title {
  padding: vw(24) 0;
  text-align: center;
  font-size: vw(32);
  color: #333;
  font-weight: 600;
}
.logo_no_height {
  height: auto;
}
</style>
